import React, { useContext, useState } from "react"
import { StoreContext } from "../../context/store-context"
import Layout from "../../components/layout"
import SectionColumns from "../../components/elements/section-columns"
import CheckoutForm from "../../components/elements/checkout-form"
import CheckoutHeader from "../../components/header/checkout-header"
import { getByCode } from "../../util/iso"
import SEO from "../../components/seo"

const Checkout = () => {
  const { cart, freeDeliveryCap } = useContext(StoreContext)
  const [validationErrors, setValidationErrors] = useState([])
  const [pageState, setPageState] = useState({ error: false, loading: false })

  const handleBuy = async ({
    code,
    dropShop,
    delivery,
    additional_info,
    ...customer
  }) => {
    setPageState({ loading: true, error: false })
    window.scrollTo(0, 0)
    const items = cart.map(item => ({
      _id: item._id,
      variantKey: item.variant._key,
    }))
    const countryName = getByCode(customer.country)
    const order = {
      code,
      dropShop,
      delivery,
      customer: { country_name: countryName.name, ...customer },
      products: items,
      additional_info,
    }
    const res = await fetch(`${process.env.GATSBY_API_BASE_URL}api/checkout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    })
    const data = await res.json()
    if (res.status === 400) {
      setValidationErrors([...data.errorMessages])
      setPageState({ loading: false, error: false })
      return
    }

    try {
      new window.PaymentWindow({
        ...data.payment,
      }).open()
    } catch {
      setPageState({ loading: false, error: true })
    } finally {
      setPageState({ loading: false, error: false })
    }
  }

  return (
    <Layout>
      <SEO title="Checkout" />
      <CheckoutHeader active="checkout" />
      {pageState.error ? (
        <div>Error</div>
      ) : (
        <>
          {pageState.loading ? (
            <div style={{ height: 500 }}>
              <div
                style={{ marginTop: 200 }}
                className="loader is-loading"
              ></div>
            </div>
          ) : (
            <SectionColumns sectionClasses="cart" isCentered>
              <div className="column is-12-tablet is-10-desktop is-9-widescreen is-9-fullhd">
                <CheckoutForm
                  handleBuy={handleBuy}
                  validationErrors={validationErrors}
                  freeDeliveryCap={freeDeliveryCap}
                />
              </div>
            </SectionColumns>
          )}
        </>
      )}
    </Layout>
  )
}

export default Checkout
