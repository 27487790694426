import React from "react"

const RadioField = ({
  children,
  name,
  onChange,
  checked,
  value,
  checkmarkTop = 0,
  checkedTop = 0,
}) => (
  <label className="container">
    <p className="label-text">{children}</p>
    <input
      value={value}
      checked={checked}
      onChange={onChange}
      type="radio"
      name={name}
    />
    <span
      className="checkmark"
      style={checkmarkTop != 0 ? { top: checkmarkTop } : null}
    ></span>
    <span
      className="checkmark--checked"
      style={checkedTop != 0 ? { top: checkedTop } : null}
    ></span>
  </label>
)

export default RadioField
