import React from "react"
import RadioField from "../forms/RadioField"

const ParcelFinder = ({
  dropPoints,
  selectedDropPoint,
  handleChange,
  loading,
}) => {
  return (
    <>
      {loading && (
        <div className="parcel-finder" style={{ minHeight: "5rem" }}>
          <div className="loader is-loading"></div>
        </div>
      )}
      {!loading && dropPoints.length > 0 && (
        <>
          <div className="parcel-finder">
            {dropPoints.map((dropPoint, i) => (
              <RadioField
                key={dropPoint.number}
                checkmarkTop={10}
                checkedTop={14}
                checked={
                  selectedDropPoint
                    ? selectedDropPoint.number === dropPoint.number
                    : false
                }
                onChange={e => handleChange({ ...dropPoint })}
              >
                {dropPoint.companyName}
                <br />
                <strong>{dropPoint.streetname}</strong> - {dropPoint.zipCode}{" "}
                {dropPoint.cityName}
              </RadioField>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default ParcelFinder
