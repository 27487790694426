import React, { useState, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { StoreContext } from "../../context/store-context"
import { VeraImageHigh } from "./vera-image"
import {
  asSingleWithAmount,
  debounce,
  deliveryMethods,
  getDeliveryObject,
} from "../../util"
import ParcelFinder from "./parcel-finder"
import RadioField from "../forms/RadioField"
import { sessionNames } from "../../util/constants"
import { countries } from "../../util/iso"

let sessionCart,
  sessionUser,
  sessionDropPoints,
  sessionSelectedDropPoint,
  sessionDropShop,
  sessionCode

if (typeof window !== "undefined") {
  sessionCart = localStorage.getItem(sessionNames.cart)
    ? JSON.parse(localStorage.getItem(sessionNames.cart))
    : []

  sessionUser = localStorage.getItem(sessionNames.formData)
    ? JSON.parse(localStorage.getItem(sessionNames.formData))
    : []

  sessionDropPoints = localStorage.getItem(sessionNames.dropPoints)
    ? JSON.parse(localStorage.getItem(sessionNames.dropPoints))
    : []

  sessionSelectedDropPoint = localStorage.getItem(
    sessionNames.selectedDropPoint
  )
    ? JSON.parse(localStorage.getItem(sessionNames.selectedDropPoint))
    : null

  sessionDropShop = localStorage.getItem(sessionNames.dropShop)
    ? JSON.parse(localStorage.getItem(sessionNames.dropShop))
    : null

  sessionCode = localStorage.getItem(sessionNames.code)
    ? JSON.parse(localStorage.getItem(sessionNames.code))
    : null
}

const CheckoutForm = ({ handleBuy, validationErrors, freeDeliveryCap }) => {
  const [delivery, setDelivery] = useState(deliveryMethods)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [dropPoints, setDropPoints] = useState([])
  const [selectedDropPoint, setSelectedDropPoint] = useState(null)
  const [dropShop, setDropShop] = useState(null)
  const {
    cart,
    fullPrice,
    loadCart,
    serializeCart,
    code,
    setCode,
  } = useContext(StoreContext)

  useEffect(() => {
    if (sessionCart.length > 0 && cart.length < 1) {
      loadCart(sessionCart)
    }
  }, [sessionCart])

  const loadParcels = async () => {
    if (street === undefined || zip === undefined) {
      setError("Please enter address.")
      return
    }

    setError(null)

    const res = await fetch(
      `${process.env.GATSBY_API_BASE_URL}api/drop-point`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ street, zip }),
      }
    )

    if (res.status !== 200) {
      return
    }

    const { parcelshops } = await res.json()
    setLoading(false)
    setDropPoints(parcelshops)
  }

  const handleDropPointChange = dropPoint => {
    setSelectedDropPoint({ ...dropPoint })
    setDropShop({ ...dropPoint })
  }

  const handleFormChange = debounce(async e => {
    setLoading(true)
    if (zip && street) {
      if (zip.length >= 4 && street.length > 3) {
        await loadParcels()
      }
    }
  }, 1000)

  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: { deliveryMethod: getDeliveryObject("pakkeshop") },
  })
  const deliveryMethod = watch("deliveryMethod")

  const isFreeDelivery = code
    ? fullPrice() - (fullPrice() / 100) * code.code_amount >= freeDeliveryCap &&
      deliveryMethod.name !== "international"
    : fullPrice() >= freeDeliveryCap && deliveryMethod.name !== "international"

  const country = watch("country")
  useEffect(() => {
    register({ name: "deliveryMethod" })
  }, [register])

  useEffect(() => {
    for (let item in sessionUser) {
      setValue(item, sessionUser[item], {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [sessionUser])

  useEffect(() => {
    if (sessionDropPoints.length > 0) {
      setDropPoints([...sessionDropPoints])
    }
  }, [sessionDropPoints])

  // useEffect(() => {
  //   if (sessionCode) {
  //     setCode({ ...sessionCode })
  //   }
  // }, [sessionCode])

  useEffect(() => {
    if (sessionSelectedDropPoint) {
      setSelectedDropPoint({ ...sessionSelectedDropPoint })
    }
  }, [sessionSelectedDropPoint])

  useEffect(() => {
    if (sessionDropShop) {
      setDropShop({ ...sessionDropShop })
    }
  }, [sessionDropShop])

  const singleItems = asSingleWithAmount(cart)

  const submit = (data, e) => {
    e.preventDefault()
    e.stopPropagation()

    for (let key in sessionNames) {
      localStorage.removeItem(sessionNames[key])
    }

    if (
      deliveryMethod &&
      deliveryMethod.name === "pakkeshop" &&
      country !== "208"
    ) {
      setError("Pakkeshop can only be selected for Danish addresses.")
      return
    }

    if (deliveryMethod && deliveryMethod.name === "pakkeshop") {
      if (dropShop === null) {
        setError("Please select a dropbox from the list")
        return
      }
      localStorage.setItem(sessionNames.dropPoints, JSON.stringify(dropPoints))
      localStorage.setItem(
        sessionNames.selectedDropPoint,
        JSON.stringify(selectedDropPoint)
      )
      localStorage.setItem(sessionNames.dropShop, JSON.stringify(dropShop))
    }

    const formValues = getValues()
    const serializedCart = serializeCart()
    localStorage.setItem(sessionNames.formData, JSON.stringify(formValues))
    localStorage.setItem(sessionNames.cart, serializedCart)
    if (code) {
      localStorage.setItem(sessionNames.code, JSON.stringify(code))
    }

    handleBuy({
      delivery: deliveryMethod.name,
      dropShop: dropShop,
      code,
      ...data,
    })
  }

  const street = watch("address")
  const zip = watch("postal_code")

  return (
    <form className="form" onSubmit={handleSubmit(submit)}>
      <div className="columns">
        <div className="column is-7">
          <h1 className="cart__heading" style={{ marginBottom: "1.25rem" }}>
            Checkout
          </h1>
          <div className="form__title">Billing Details</div>
          <div className="columns">
            <div className="column">
              <div className="input-group">
                <label className="label">First name</label>
                <div className="required">
                  <input
                    name="firstname"
                    ref={register({
                      required: "Please enter first name",
                    })}
                    type="text"
                    className="input"
                  />
                  <p className="form__input-error">
                    {errors.firstname?.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="input-group">
                <label className="label">Surname</label>
                <div className="required">
                  <input
                    name="surname"
                    ref={register({ required: "Please enter surname" })}
                    type="text"
                    className="input"
                  />
                  <p className="form__input-error">{errors.surname?.message}</p>
                </div>
              </div>
            </div>
          </div>
          <FormField
            name="company_name"
            label="Company name (optional)"
            register={register}
            type="text"
            placeholder="Type in company name"
          />

          <FormField
            name="address"
            label="Street address"
            register={register}
            type="text"
            placeholder="Street name and house number"
            required
            two
            placeholderTwo="Apartment, suite, unit etc. "
            errors={errors}
            errorMessage="Please enter your address"
            onChange={handleFormChange}
          />

          <FormField
            name="postal_code"
            label="Post code / ZIP"
            register={register}
            type="text"
            required
            placeholder="Type in postcode"
            errors={errors}
            errorMessage="Please enter post code / ZIP"
            onChange={handleFormChange}
          />

          <FormField
            name="city"
            label="City"
            register={register}
            required
            errors={errors}
            errorMessage="Please enter city"
            type="text"
            placeholder="Type in city"
          />
          <div className="columns">
            <div className="column">
              <div className="input-group">
                <div className="required">
                  <select
                    className="input"
                    name="country"
                    ref={register({ required: "Please select your country" })}
                    errors={errors}
                  >
                    <option key={0} value="">
                      Select Country
                    </option>
                    {countries.map(country => (
                      <option
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <p className="form__input-error">{errors.country?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <FormField
            name="phone_number"
            label="Phone"
            register={register}
            type="text"
            placeholder="Type in phone"
            required
            errors={errors}
            errorMessage="Please enter your phone number"
          />

          <FormField
            name="email"
            label="Email"
            register={register}
            type="email"
            placeholder="Type in email"
            required
            errors={errors}
            pattern={
              /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            }
            errorMessage="Please enter your email address"
            patternMessage="Please provide a valid email address"
          />
          <div className="columns">
            <div className="column">
              <div className="input-group">
                <label className="label">
                  Additional Information
                  <br /> (if you need another shipping address, please enter
                  here)
                </label>
                <textarea
                  name="additional_info"
                  ref={register}
                  className="input input__textarea"
                />
              </div>
            </div>
          </div>
          {delivery.map((del, i) => {
            return (
              <RadioField
                key={del.name}
                onChange={() => {
                  if (dropPoints.length === 0) {
                    handleFormChange()
                  }
                  setError(null)
                  setValue("deliveryMethod", { ...del })
                }}
                name="delivery"
                value={deliveryMethod}
                checked={deliveryMethod.name === del.name}
              >
                {del.title}
              </RadioField>
            )
          })}
        </div>
      </div>
      <div className="columns">
        <div className="column is-7">
          {deliveryMethod &&
            deliveryMethod.name &&
            deliveryMethod.name === "pakkeshop" &&
            country === "208" && (
              <ParcelFinder
                loading={loading}
                dropPoints={dropPoints}
                selectedDropPoint={selectedDropPoint}
                handleChange={handleDropPointChange}
              />
            )}
          <p className="form__input-error">{error}</p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-7 ">
          <h3 className="cart__heading" style={{ marginBottom: "1.25rem" }}>
            Your bag
          </h3>
          <div className="cart__checkout-bag">
            {singleItems.map(product => {
              return (
                <div key={product._id} className="columns is-gapless is-mobile">
                  <div className="column is-3-tablet is-2-desktop">
                    <div className="cart__checkout-bag-image">
                      <VeraImageHigh
                        contain
                        asset={product.product_image.asset}
                      />
                    </div>
                  </div>
                  <div className="column is-8-tablet">
                    <div className="cart__checkout-bag-title-wrapper">
                      <p className="cart__checkout-bag-title">
                        {product.product_name}
                      </p>
                      <p className="cart__checkout-bag-info">
                        <span style={{ marginRight: "1rem" }}>
                          {product.variant_type}{" "}
                          {product.variant.variant_info_value}
                        </span>
                        <span>
                          Amount: {product.amount} x{" "}
                          {product.variant.variant_info_price} DKK
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="column"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "0.5rem",
                    }}
                  >
                    {product.amount * product.variant.variant_info_price} DKK
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="column">
          <div className="cart__overview">
            <h3 className="cart__heading" style={{ marginBottom: "1.25rem" }}>
              Summary
            </h3>
            <div className="cart__info-box" style={{ fontSize: "1rem" }}>
              <div className="cart__price-info">
                <p>Subtotal:</p>
                <p style={{ color: "#757575" }}>{fullPrice()} DKK</p>
              </div>
              {code && (
                <div className="cart__price-info">
                  <p>Discount:</p>
                  <p style={{ color: "#757575" }}>
                    - {Math.round((fullPrice() / 100) * code.code_amount)} DKK
                  </p>
                </div>
              )}
              <div style={{ marginBottom: 0 }} className="cart__price-info">
                <p>Shipping:</p>
                <p style={{ color: "#757575" }}>
                  {deliveryMethod
                    ? `${isFreeDelivery ? 0 : deliveryMethod.price} DKK`
                    : "Please select a delivery method"}{" "}
                </p>
              </div>
              <p style={{ color: "#757575", marginBottom: "1rem" }}>
                {deliveryMethod ? deliveryMethod.title : ""}
              </p>
              <hr
                style={{
                  border: "0.5px solid black",
                  marginBottom: "1rem",
                }}
              />
              <div
                style={{ marginBottom: 0, fontWeight: "bold" }}
                className="cart__price-info"
              >
                <p>Total:</p>
                <p>
                  {deliveryMethod
                    ? `${
                        isFreeDelivery
                          ? code
                            ? fullPrice() -
                              Math.round((fullPrice() / 100) * code.code_amount)
                            : fullPrice()
                          : code
                          ? fullPrice() -
                            Math.round((fullPrice() / 100) * code.code_amount) +
                            deliveryMethod.price
                          : fullPrice() + deliveryMethod.price
                      } DKK`
                    : ""}
                </p>
              </div>
            </div>
            {cart.length > 0 ? (
              <button type="submit" className="cart__place-order-btn">
                Place Order
              </button>
            ) : (
              <button
                title="No items in cart"
                type="submit"
                disabled
                style={{ cursor: "not-allowed" }}
                className="cart__place-order-btn"
              >
                Place Order
              </button>
            )}

            <div className="validation-errors">
              {validationErrors.length > 0 ? (
                <p style={{ marginTop: "1rem" }}>
                  <b>Errors in the request</b>
                </p>
              ) : null}
              {validationErrors.map((error, i) => (
                <p key={`${error}_${i}`} style={{ color: "red" }}>
                  {error}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const FormField = ({
  required,
  name,
  register,
  type,
  label,
  placeholder,
  two,
  placeholderTwo,
  pattern,
  errors,
  errorMessage,
  patternMessage,
  onChange,
}) => {
  const input = required ? (
    <div className="required">
      <input
        onChange={onChange}
        className="input"
        placeholder={placeholder}
        name={name}
        ref={register({
          pattern: pattern ? pattern : null,
          required: required ? errorMessage : null,
        })}
        type={type}
      />
      {two && (
        <input
          style={{ marginTop: "0.5rem" }}
          className="input"
          type={type}
          ref={register}
          name={`${name}_2`}
          placeholder={placeholderTwo}
        />
      )}
    </div>
  ) : (
    <>
      <input
        className="input"
        name={name}
        ref={register}
        type={type}
        placeholder={placeholder}
      />
      {two && (
        <input
          style={{ marginTop: "0.5rem" }}
          className="input"
          type={type}
          ref={register}
          name={`${name}_2`}
        />
      )}
    </>
  )

  return (
    <div className="columns">
      <div className="column">
        <div className="input-group">
          {label && <label className="label">{label}</label>}
          {input}
          {errors && errors[name]?.type === "pattern" ? (
            <p className="form__input-error">{patternMessage}</p>
          ) : null}
          <p className="form__input-error">{errors && errors[name]?.message}</p>
        </div>
      </div>
    </div>
  )
}

export default CheckoutForm
